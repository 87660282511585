<!--
 * @Description:: 请补充填写
 * @Version: 请配置版本号
 * @Author: xiaobai
 * @Date: 2021-06-11 15:34:25
 * @LastEditors: smallWhite
 * @LastEditTime: 2023-11-22 14:59:10
 * @FilePath: \sipingnongzhuan2\src\views\xi\index.vue
-->
<template>
  <div class="news_content"
    style="max-width:100%">
    <div class="news_details">
      <div v-if="details"
        style="
    padding:0 5px;
    background: rgb(255, 255, 255);
    overflow: hidden;
    max-width: 1160px;
    margin: 0px auto !important;">
        <div class="imgJs"
          v-html="details">
        </div>
      </div>
      <div v-else
        style="width:100%;text-align:center">
        <img
          style="width:250px;margin:auto"
          src="../../assets/imgaes/no_data.png"
          alt="">
      </div>
      <div @click="goPage()"
        class="news_btn news_btn_btn">
        <span
          class="news_btn_span">返回首页&gt;&gt;</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['value'],
  data() {
    return {
      isPhone: 'false',
      details: '',
      id: ''
    }
  },
  watch: {
    $route: {
      handler: function () {
        this.getNews()
      },
      // 深度观察监听
      deep: true
    }
  },
  mounted() {
    this.getNews()
  },
  methods: {
    getNews() {
      const obj = {
        pageNum: 1,
        pageSize: 999,
        isEnable: 0,
        projectId: this.$route.query.id
      }
      this.$post('/detailsContent/selectListByProjectId', obj).then(res => {
        if (res.data.list.length > 0) {
          this.details = res.data.list[0].content
        } else {
          this.details = ''
        }
      })
    },
    goPage() {
      this.$router.push('/home')
    }
  }
}
</script>

<style>
.imgJs img {
  /* max-width: calc(100% -10px); */
  /* width: 100%; */
}
.news_btn_btn {
  float: inherit;
}
.news_content .news_details .news_btn.news_btn_btn .news_btn_span {
  margin: 20px auto !important;
}
.news_detail_content p img {
  display: inline !important;
}
.news_content img {
  max-width: calc(100% - 40px);
}
.news_content table {
  max-width: calc(100% - 40px);
}
</style>